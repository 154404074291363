import styled from "styled-components";
import vr from "./components/assets/images/vr.svg";
import screen1_man from "./components/assets/images/screen1_man.png";
import containerFiller1 from "./components/assets/images/Road_SignL032k.png";
import containerFiller2 from "./components/assets/images/Road_SignN082k.png";
import containerFiller3 from "./components/assets/images/Road_SignO082k.png";
import containerFillerDialog from "./components/assets/images/Group_898.png";
import footerLogo from './components/assets/images/footer-logo.svg';
import SliderComponent from "./components/SliderComponent";
import CourseComponent from "./components/CourseComponent";
import SocialsComponent from "./components/SocialsComponent";
import AboutComponent from "./components/AboutComponent";
import ProgressBlock from "./components/ProgressBlock";
import StartComponent from "./components/StartComponent";
// import ScheduleComponent from "./components/ScheduleComponent";
import FilledImageComponent from "./components/FilledImageComponent";
import VideoFeedbackComponent from "./components/VideoFeedbackComponent";
import FeedbackComponent from "./components/FeedbackComponent";
import WhyComponent from "./components/WhyComponent";
import FillerCarComponent from "./components/FillerCarComponent";
import FooterComponent from "./components/FooterComponent";
import logo from './components/assets/images/Group_894.svg';
import './styles.css';
import './components/assets/styles/globals.css'
import {useDispatch} from "react-redux";
import {openModal} from "../../layout";
import {useEffect, useState} from "react";
import {getInfoSchoolSiteData} from "../../api/CabinetPromoApi";
import './components/assets/styles/connect.css';

export const Login = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState(null);

  const openModalLogin = () => {
    dispatch(openModal({ name: "LoginModal" }));
  };


  const mobile = window.innerWidth <= 768;
  // const slideIndex = useState(0);


  useEffect(() => {
    const getData = async () => {
      const siteData =await  getInfoSchoolSiteData(window.location.host.split('.pddtv.ru')[0])
      setData(siteData.data);
    }

    getData();
  }, []);


  if (!data) {
    return null;
  }

  return (
      <>
        <MainContainer>
          {/*<GlobalStyle />*/}
          <MainContentWrapper>

            {mobile && <>
              <MobileLoginButton onClick={openModalLogin}>Войти</MobileLoginButton>
              <MobileLogo></MobileLogo>
              <MinContainer>
                <MinContainerImage />
                <HeaderBottomSmallGrayText style={{textAlign: 'center'}}>Образовательные услуги оказываются ПАО «Светофор Групп» на основании Лицензии № Л035−1 298−77/408 576 от 9 июня 2022 года и партнёрами.</HeaderBottomSmallGrayText>

              </MinContainer>
            </>}
            <ContainerTop>
              <ContainerTopWrapper>

                <ExtraTitleTop>Курс теории для будущих водителей</ExtraTitleTop>

                <ContainerTopOutline>
                  <ContainerTopH>
                    ПДД.ТВ это - удобное изучение теории в режиме онлайн
                  </ContainerTopH>
                  <ContainerTopButtons>
                    <OutlineBlock>Доступно в IOS, Android</OutlineBlock>
                    <OutlineBlock>300 000+ учеников</OutlineBlock>
                    <ContainerTopButtonsVr>
                      <ContainerTopButtonsVrImage />
                    </ContainerTopButtonsVr>
                    <OutlineBlock>Чат с преподавателем</OutlineBlock>

                    <OutlineBlock>Поддержка на всех этапах обучения</OutlineBlock>
                  </ContainerTopButtons>

                  <HorizontalWrapper gap={20}>
                    <GreenButton onClick={() => {
                      window.location = data.reg_link;
                    }}>Регистрация</GreenButton>
                    {!mobile && <WhiteButton onClick={openModalLogin}>Войти</WhiteButton>}
                  </HorizontalWrapper>
                </ContainerTopOutline>
              </ContainerTopWrapper>
              <AbsoluteFillerImage
                  src={screen1_man}
                  width={mobile ? 348 : 597}
                  left={mobile ? window.innerWidth / 2 - 174 : 512}
                  height={mobile ? 348 : 597}
                  bottom={mobile ? 40 : 0}
              />
              <AbsoluteFillerImage
                  src={containerFiller1}
                  width={mobile ? 105 : 112}
                  left={mobile ? window.innerWidth/2 - 170 : 1072}

                  height={mobile ? 105 : 112}
                  top={!mobile && 407}
                  bottom={mobile && 250}
              />
              <AbsoluteFillerImage
                  src={containerFiller2}
                  width={mobile ? 80 : 138}
                  rotate={344}
                  left={mobile ? window.innerWidth/2 - 80 : 564}
                  height={mobile ? 80 : 138}
                  top={!mobile && 132}
                  bottom={mobile && 120}
              />
              <AbsoluteFillerImage
                  src={containerFiller3}
                  width={mobile ? 90 : 170}
                  height={mobile ? 90 : 170}
                  rotate={95}
                  left={mobile ? window.innerWidth / 2 + 60 : 934}
                  top={!mobile && 21}
                  bottom={mobile && 250}
              />
              <AbsoluteFillerImage
                  src={containerFillerDialog}
                  width={mobile ? 176 : 331}
                  height={mobile ? 123 : 231}
                  right={0}
                  top={!mobile && 202}
                  bottom={mobile && 135}
              />
            </ContainerTop>

            <CategoriesBlockWrapper>
              <Subtitle>Категории на которые мы обучаем</Subtitle>
              <HugeLetters>{data.category}</HugeLetters>
              <ConsultButton as="a" href="#consult">
                Консультация →{" "}
              </ConsultButton>
            </CategoriesBlockWrapper>

            <SliderComponent
                data={data}
                Title={Title}
                primary={primary}
                green={green}
            />
            <CourseComponent
                Title={Title}
                primary={primary}
            />
            <SocialsComponent
                Title={Title}
                primary={primary}
                link={link}
                green={green}
                logo={data.partner_logo}
                data={data}
            />
            <AboutComponent
                ExtraTitle={ExtraTitle}
                primary={primary}
            ></AboutComponent>
            <ProgressBlock
                ExtraTitle={ExtraTitle}
                ConsultButton={ConsultButton}
                green={green}
                primary={primary}
            ></ProgressBlock>
            <StartComponent
                ExtraTitle={ExtraTitle}
                Text={styled(ContainerTopText)`
              color: ${primary};
              margin-top: 0;
              margin-bottom: 40px;
            `}
                primary={primary}
                ConsultButton={styled(ConsultButton)`
              padding-inline: 50px;
              width: 100%;

              text-align: center;
            `}
            />
            {/*<ScheduleComponent*/}
            {/*    ExtraTitle={ExtraTitle}*/}
            {/*    GreenButton={styled(GreenButton)`*/}
            {/*  text-align: center;*/}
            {/*  line-height: 55px;*/}
            {/*  &:hover {*/}
            {/*    color: white;*/}
            {/*    background: ${primary};*/}
            {/*  }*/}
            {/*`}*/}
            {/*    Text={styled(ContainerTopText)`*/}
            {/*  color: ${primary};*/}
            {/*  margin-top: 0;*/}
            {/*  margin-bottom: 36px;*/}
            {/*`}*/}
            {/*    primary={primary}*/}
            {/*></ScheduleComponent>*/}
            <FilledImageComponent
                Text={ContainerTopText}
                ExtraTitle={ExtraTitle}
            />
            <VideoFeedbackComponent
                Text={ContainerTopText}
                ExtraTitle={ExtraTitle}
                primary={primary}
            />
            <FeedbackComponent
                Text={ContainerTopText}
                ExtraTitle={ExtraTitle}
                primary={primary}
            />
            <WhyComponent
                Text={ContainerTopText}
                Title={Title}
                primary={primary}
            />
            <FillerCarComponent  ExtraTitle={ExtraTitle} />
            <FooterComponent data={data} />
          </MainContentWrapper>
        </MainContainer>
      </>
  );
}


//
// const GlobalStyle = createGlobalStyle`
//   @keyframes textSlide1 {
//   0% {
//   transform: translate(0);
//   },
//   100% {
//   transform: translate(-2932px);
//   }
//   }
//    @keyframes textSlide2 {
//   0% {
//   transform: translate(0);
//   },
//   100% {
//   transform: translate(2008px);
//   }
//   }
//      @keyframes textSlide3 {
//   0% {
//   transform: translate(0);
//   },
//   100% {
//   transform: translate(-2819px);
//   }
//   }
//   @media (max-width: 768px) {
//   .slider-item {
//  flex-direction: column;
//  max-width: 100%!important;
//  min-width: 100%!important;
//  gap: 12px!important;
// align-self: stretch;
// >img {
// min-width: 100%!important;
// max-width: 100%!important;
// }
// >div {
//  padding: 30px!important;
// gap: 10px!important;
// justify-self: stretch!important;
//
// >h3 {
// font-size: 20px!important;
// line-height: 22px!important;
// }
// >p {
// font-size: 14px!important;
// line-height: 19px!important;
// }
// }
//   }
// }
//   `;
//

const green = "#00ab6d";

const primary = "#17171b";
const textColor = "#f9f9f9";
const link = "#ff8562";

const MainContainer = styled.div`
    max-width: 100%;
    width: 100%;
    max-height: 100svh;
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;
  font-family:  'GraphikTT',Arial,sans-serif !important;
`;

const MainContentWrapper = styled.div`
    max-width: 1200px;
    margin-inline: auto;

    display: flex;
    flex-direction: column;
  `;

const Container = styled.div`
    max-width: calc(100vw - 20px);
    margin-bottom: 140px;
    width: 100%;
    margin-inline: auto;
    @media (max-width: 768px) {
      margin-bottom: 30px !important;
    }
  `;

const ContainerTop = styled.div`
    background: ${primary};
    padding-inline: 40px;
    padding-top: 60px;
    margin-bottom: 20px;
    position: relative;
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;
    @media (max-width: 768px) {
      padding: 30px;
      margin-bottom: 350px;
    }
  `;
const ContainerTopWrapper = styled.div`
    display: flex;
    flex-direction: column;

    max-width: 503px;
    padding-bottom: 152px;
    @media (max-width: 768px) {
      padding-bottom: 340px;
    }
  `;

const Title = styled.h1`
    font-size: 44px;
    color: ${primary};
    font-weight: 600;
    line-height: 48px;
    max-width: 100%;
    @media (max-width: 768px) {
      font-size: 26px;
      line-height: 27px;
    }
  `;

const ExtraTitle = styled.h1`
    font-size: 50px;
    color: ${textColor};
    font-weight: 600;
    line-height: 55px;
    max-width: 100%;
    @media (max-width: 768px) {
      font-size: 26px !important;
      line-height: 27px !important;
    }
  `;
const ExtraTitleTop = styled(ExtraTitle)`
    @media (max-width: 768px) {
      text-align: center;
      font-size: 40px !important;
      line-height: 42px !important;
    }
  `;

const Subtitle = styled.h2`
    color: ${primary};
    font-size: 30px;
    font-weight: 600;
    text-align: center;
    line-height: 35px;
    @media (max-width: 768px) {
      font-size: 18px;
      line-height: 20px;
    }
  `;
const HugeLetters = styled.h1`
    line-height: 80px;
    font-size: 80px;
    font-weight: 600;
    color: ${primary};
    @media (max-width: 768px) {
      font-size: 38px;
      line-height: 40px;
      margin-bottom: 10px;
    }
  `;

const ContainerTopText = styled.p`
    font-size: 18px;

    font-weight: 400;
    line-height: 28px;
    margin-bottom: 20px;
    color: white;
    margin-top: 20px;
    @media (max-width: 768px) {
      font-size: 14px !important;
      line-height: 20px !important;
    }
  `;
const ContainerTopH = styled(ContainerTopText)`
    @media (max-width: 768px) {
      font-size: 20px !important;
      line-height: 22px !important;
      font-weight: 600;
    }
  `;
const ContainerTopButtons = styled.div`
    display: flex;
    gap: 10px;
    margin-bottom: 50px;
    flex-wrap: wrap;
    @media (max-width: 768px) {
      margin-bottom: 35px;
    }
  `;
const GreenButton = styled.button`
    font-size: 18px;
    height: 55px;
    width: 100%;
    padding-inline: 60px;
    background: ${green};
    border-radius: 12px;
    color: white;
    transition: 0.2s;
    &:hover {
      background: white;
      color: ${primary};
    }
    @media (max-width: 768px) {
      padding-inline: 0;
    }
  `;
const WhiteButton = styled.button`
    font-size: 18px;
    height: 55px;
    background: white;
    padding-inline: 60px;
    border-radius: 12px;
    color: ${primary};
    transition: 0.2s;
    &:hover {
      background: ${green};
      color: white;
    }
  `;
const HorizontalWrapper = styled.div`
    display: flex;
    flex-wrap: ${(props) => (props.wrap ? "wrap" : "nowrap")};
    gap: ${(props) => (props.gap > 0 ? props.gap + "px" : "12px")};
  `;
const OutlineBlock = styled.div`
    font-size: 14px;
    color: white;
    line-height: 22px;
    align-items: center;
    display: flex;
    padding: 11px 12px;
    border: 1px solid white;
    border-radius: 12px;
    @media (max-width: 768px) {
      padding: 8px 12px;
      line-height: 14px;
    }
  `;
const ContainerTopButtonsVr = styled.div`
    height: 46px;
    background: white;
    width: 61px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid white;
    border-radius: 12px;
    @media (max-width: 768px) {
      width: 50px;
      height: 36px;
    }
  `;
const ContainerTopButtonsVrImage = styled.div`
    width: 41px;
    height: 30px;
    background: url(${vr});
    background-size: cover;
  `;
const AbsoluteFillerImage = styled.div`
    background: url(${(props) => props.src});
    position: absolute;
    right: ${(props) => (props.right != null ? props.right + "px" : "auto")};
    top: ${(props) => (props.top != null ? props.top + "px" : "auto")};
    left: ${(props) => (props.left != null ? props.left + "px" : "auto")};
    bottom: ${(props) => (props.bottom != null ? props.bottom + "px" : "auto")};
    width: ${(props) => (props.width != null ? props.width + "px" : "auto")};
    height: ${(props) => (props.height != null ? props.height + "px" : "auto")};
    transform: rotate(
      ${(props) => (props.rotate != null ? props.rotate : 0)}deg
    );
    background-size: cover;
  `;
const ConsultButton = styled.a`
    background: #f5f5f5;
    padding-inline: 190px;
    line-height: 55px;
    color: ${primary};
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out,
      border-color 0.2s ease-in-out;
    border-radius: 12px;
    &:hover {
      color: white;
      background: ${primary};
    }
    @media (max-width: 768px) {
      padding-inline: 0 !important;
      width: 100%;
      text-align: center;
      max-width: 100%;
    }
  `;
const CategoriesBlockWrapper = styled.div`
    max-width: calc(100% - 40px);

    margin-bottom: 140px;
    margin-inline: auto;
    display: flex;
    gap: 30px;
    flex-direction: column;
    align-items: center;
    width: 100%;
    border: #17171b4d 1px solid;
    padding: 40px 20px;
    border-radius: 30px;
    @media (max-width: 768px) {
      gap: 10px;
      margin-bottom: 30px;
      padding: 30px 20px;
    }
  `;
const ContainerTopOutline = styled.div`
    @media (max-width: 768px) {
      position: absolute;
      width: calc(100% - 20px);
      left: 10px;
      top: calc(100% - 100px);
      background-color: #23232a;
      border-radius: 24px;
      padding: 30px 20px;
      z-index: 20;
    }
  `;
const MinContainer = styled.div`
  display: flex;
  align-items: center;
  border-top: 1px solid #ffffff7f;
flex-direction: column;
    width:100%;
    margin: 0 auto;
    gap: 12px;
padding: 20px 0 0 0;
background: ${primary};

  `
const MobileLogo = styled.div`
  background: url(${logo});
  background-size: cover;
  width: 88px;
  height: 39px;
  position: absolute;
  left: 10px;
  top: 20px;
  `
const MobileLoginButton = styled.button`
  font-size: 12px;
  background: white;
  top: 20px;
  right: 10px;
  width: 107px;
  height: 36px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  position: absolute;
  border-radius: 10px;
  color: ${primary};
  `
const MinContainerImage = styled.div`
  width: 207px;
  height: 248px;

background: url(${footerLogo});

background-size: cover;`
const HeaderBottomSmallGrayText = styled.p`
  color: white;

  color: #ffffff;
width: calc(100% - 80px);
margin-inline: auto;
  padding-bottom: 20px;
  border-bottom: 1px solid white;
  
  line-height: 14px;
  font-size: 10px;
`;
